/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { navigate } from 'gatsby';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';
// UI lib component
import { Container, Visible } from 'react-grid-system';

// Assetes
import SLOGAN_ICON from '../../../images/icons/iso.png';
import PHONE_ICON from '../../../images/icons/phone-icon.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                      Banner Section Of Page Index                          */
/* -------------------------------------------------------------------------- */

function BannerSectionIndex() {
  // Localization
  const { t } = useTranslation();
  const { title, tel, subTitle, description, btn } = t('BannerSection', {
    returnObjects: true,
  });

  /* ******************************* RENDERING ****************************** */
  return (
    <>
      <Container className="banner-index flex flex-column justify-center fluid">
        <Visible xs sm md>
          <Container
            className="single-element flex items-center fluid"
            onClick={() => window.open('tel:+21671752300')}
          >
            <img className="icon" src={PHONE_ICON} alt="phone-icon" />
            <p className="text">{tel}</p>
          </Container>
        </Visible>
        <h1 className="title">{title}</h1>
        <Link to="iso-presentation" className="iso_logo">
          <img src={SLOGAN_ICON} alt="logo iso in red" />
        </Link>
        <span className="slogan">{subTitle}</span>
        <p>{description}</p>
        <button type="submit" onClick={() => navigate('/presentation')}>
          {btn}
        </button>
      </Container>
    </>
  );
}

export default BannerSectionIndex;

/* eslint-disable react/jsx-no-bind */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';

// UI lib components
import {
  Col,
  Container,
  Row,
  Visible,
  useScreenClass,
} from 'react-grid-system';

// UI local components
import TitleSection from '../titleSection';
import SinglePartner from '../../single-partner';

// Assets
import PREV_ARROW from '../../../images/detail-reference/prev-arrow.svg';
import NEXT_ARROW_CAROUSEL from '../../../images/detail-reference/next-arrow.svg';
import NEXT_ARROW from '../../../images/index/icons/blue-arrow.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                     Our Partners Section Of Page Index                     */
/* -------------------------------------------------------------------------- */

function OurPartners() {
  /* -------------------------------- HOOKS --------------------------------- */
  const [partners, setPartners] = useState([]);
  const { language } = useI18next();
  /* -------------------------------- CONST --------------------------------- */
  const screenClass = useScreenClass();
  const IS_MOBILE = ['xs', 'sm', 'md'].includes(screenClass);

  useEffect(() => {
    if (language === 'fr') {
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://admin.luxor.com.tn/api/1.1/tables/partner/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
        )
        .then(({ data: { data } }) => {
          setPartners(data);
        })
        .catch((error) => error);
    } else if (language === 'en') {
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://admin.luxor.com.tn/api/1.1/tables/partneren/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
        )
        .then(({ data: { data } }) => {
          setPartners(data);
        })
        .catch((error) => error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  function getSliderArrowPrev(clickHandler, hasPrev) {
    if (hasPrev) {
      return (
        <button
          className="carousel-icon-prev"
          type="button"
          name="carousel-icon-prev"
          onClick={clickHandler}
        >
          <img src={PREV_ARROW} alt="previous-arrow" />
        </button>
      );
    }
    return '';
  }

  function getSliderArrowNext(clickHandler, hasNext) {
    if (hasNext) {
      return (
        <button
          className="carousel-icon-next"
          type="button"
          name="carousel-icon-next"
          onClick={clickHandler}
        >
          <img src={NEXT_ARROW_CAROUSEL} alt="next-arrow" />
        </button>
      );
    }
    return '';
  }
  const { t } = useTranslation();
  const { title, pageLink, titleLink, btn } = t('ourPartners', {
    returnObjects: true,
  });

  /* ******************************* RENDERING ****************************** */
  return (
    <Container id="partners" className="our-partners fluid" fluid>
      <TitleSection pageLink={pageLink} title={title} titleLink={titleLink} />
      <Visible lg xl xxl>
        <Row className="partners-section fluid">
          {partners
            ?.sort((elm1, elm2) => elm1.rang - elm2.rang)
            .slice(0, 4)
            .map((element) => (
              <SinglePartner
                key={element.id}
                id={element.id}
                image={`https://admin.luxor.com.tn/${element.logo?.data.url}`}
                description={element.description}
                href={element.website_text}
              />
            ))}
        </Row>
      </Visible>
      <Visible sm md xs>
        <Container className="details-reference-container max-w top">
          <Carousel
            className="reference-carousel"
            showIndicators={false}
            showArrows
            useKeyboardArrows
            emulateTouch
            interval={5000}
            width={IS_MOBILE ? '100%' : '80%'}
            renderArrowPrev={getSliderArrowPrev}
            renderArrowNext={getSliderArrowNext}
            showThumbs={false}
            showStatus={false}
            swipeable
          >
            {partners
              ?.sort((elm1, elm2) => elm1.rang - elm2.rang)
              .map((element) => (
                <Col
                  className="single-partner-container fluid"
                  xxl={3}
                  xl={3}
                  lg={4}
                  md={6}
                  sm={12}
                  xs={12}
                >
                  <Container className="single-partner text-center fluid" fluid>
                    <img
                      src={`https://admin.luxor.com.tn/${element.logo?.data.url}`}
                      alt=""
                    />
                    <p>{element.description}</p>
                    <a
                      className="website-link flex items-center justify-center"
                      href={element.website_text}
                      target="blank"
                    >
                      <h3>{btn}</h3>
                      <img src={NEXT_ARROW} alt="next-arrow" />
                    </a>
                  </Container>
                </Col>
              ))}
          </Carousel>
        </Container>
      </Visible>
    </Container>
  );
}

export default OurPartners;

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Carousel from 'react-elastic-carousel';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { useI18next } from 'gatsby-plugin-react-i18next';
// UI lib components
import { Container, useScreenClass } from 'react-grid-system';

// UI local components
import TitleSection from '../titleSection';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                     Our Partners Section Of Page Index                     */
/* -------------------------------------------------------------------------- */

function OurSolutions() {
  /* ******************************* CONSTANTS ****************************** */
  const API_LINK = 'https://admin.luxor.com.tn';
  /* ******************************** HOOKS ********************************* */
  const { language } = useI18next();
  const [solutions, setSolutions] = useState([]);
  const screenClass = useScreenClass();
  useEffect(() => {
    if (language === 'fr') {
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://admin.luxor.com.tn/api/1.1/tables/solution_level_1/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
        )
        .then(({ data: { data } }) => {
          setSolutions(data);
        })
        .catch((error) => error);
    } else if (language === 'en') {
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://admin.luxor.com.tn/api/1.1/tables/solution_level_1_en/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
        )
        .then(({ data: { data } }) => {
          setSolutions(data);
        })
        .catch((error) => error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);
  /* ******************************* RENDERING ****************************** */
  // LOCALIZATION
  const { t } = useTranslation();
  const { title, pageLink, titleLink } = t('solution', { returnObjects: true });
  return (
    <Container className="our-solutions fluid" fluid>
      <TitleSection pageLink={pageLink} title={title} titleLink={titleLink} />
      <Container className="solutions-container flex fluid" fluid>
        <Carousel
          // enableAutoPlay
          autoPlaySpeed={5000}
          enableSwipe
          showArrows={false}
          itemsToShow={['xs', 'sm', 'md', 'lg'].includes(screenClass) ? 1 : 4}
        >
          {solutions?.map((element) => (
            <Link
              className="single-solution-index-container fluid"
              to={`/solutions/${element.slug}`}
              key={element.id}
            >
              <Container className="single-solution-index text-center fluid">
                <img src={API_LINK + element?.image?.data.url} alt="" />
                <h3>{element.title}</h3>
              </Container>
            </Link>
          ))}
        </Carousel>
      </Container>
      <div className="d-none">
        {solutions?.map((element) => (
          <Link to={`/solutions/${element.slug}`} key={element.id}>
            {element.title}
          </Link>
        ))}
      </div>
    </Container>
  );
}

export default OurSolutions;

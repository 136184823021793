/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages

import React from 'react';

// UI lib component
import { Container } from 'react-grid-system';
import { useTranslation } from 'react-i18next';
// Assets
import SLOGAN_ICON from '../../../images/index/slogan-section/quote.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                       Slogan Section Page Index                            */
/* -------------------------------------------------------------------------- */

function SloganSection() {
  // localization
  const { t } = useTranslation();
  const { title } = t('slogan', { returnObjects: true });

  /* ******************************** RENDERING ******************************* */
  return (
    <Container
      className="slogan-section flex flex-column items-center justify-center fluid"
      fluid
    >
      <div className="blue-reactangle" />
      <img src={SLOGAN_ICON} alt="quote_icon" />
      <h2 className="text-center" data-aos="fade-up" data-aos-duration="1000">
        {title}
      </h2>
    </Container>
  );
}

export default SloganSection;

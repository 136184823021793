/* eslint-disable jsx-a11y/alt-text */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { navigate } from 'gatsby';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';
// UI lib components
import { Container, Row } from 'react-grid-system';

// UI local components
// import { element } from 'prop-types';
import TitleSection from '../titleSection';
import SingleReference from '../../single-reference';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                    Our Reference Section Of Page Index                     */
/* -------------------------------------------------------------------------- */

function OurReferences() {
  /* -------------------------------- HOOKS --------------------------------- */
  const [references, setReferences] = useState([]);
  /* -------------------------------- CONST --------------------------------- */
  const { language } = useI18next();
  // const IS_BROWSER = typeof window !== 'undefined';
  // eslint-disable-next-line operator-linebreak
  // const REFERENCES_STORAGE =
  //   IS_BROWSER && localStorage.getItem('references-array');
  // const referencesTab = JSON.parse(REFERENCES_STORAGE);
  useEffect(() => {
    if (language === 'fr') {
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://admin.luxor.com.tn/api/1.1/tables/reference/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
        )
        .then(({ data: { data } }) => {
          localStorage.setItem('references-array', JSON.stringify(data));
          setReferences(data);
        })
        .catch((error) => error);
    } else if (language === 'en') {
      axios
        .get(
          // eslint-disable-next-line max-len
          'https://admin.luxor.com.tn/api/1.1/tables/referenceen/rows?access_token=doZlr3zfCrpKsUaIC1OYKzbplQhGNU6c',
        )
        .then(({ data: { data } }) => {
          localStorage.setItem('references-array', JSON.stringify(data));
          setReferences(data);
        })
        .catch((error) => error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  /* ********************************* HELPERS ******************************** */
  function getLink() {
    return '/details-reference';
  }

  // LOCALIZATION
  const { t } = useTranslation();
  const { titleSection, pageLink, titleLink } = t('reference', {
    returnObjects: true,
  });
  /* ******************************* RENDERING ****************************** */
  return (
    <Container id="references" className="our-references max-w fluid">
      <TitleSection
        pageLink={pageLink}
        title={titleSection}
        titleLink={titleLink}
      />
      <Container className="fluid" fluid>
        <Row className="single-reference-wrapper fluid">
          {references
            ?.sort((elm1, elm2) => elm1.rank - elm2.rank)
            .slice(0, 4)
            .map((element) => {
              return (
                <SingleReference
                  key={element.id}
                  id={element.id}
                  name={element.project_name}
                  image={`https://admin.luxor.com.tn/${element.main_picture?.data.url}`}
                  alternative={element.project_name}
                  onclick={() => {
                    localStorage.setItem(
                      'singleReference',
                      JSON.stringify(element),
                    );
                    navigate(getLink());
                  }}
                />
              );
            })}
        </Row>
      </Container>
    </Container>
  );
}

export default OurReferences;

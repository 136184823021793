/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
import { useTranslation } from 'react-i18next';
// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Col } from 'react-grid-system';

// Assets
import NEXT_ARROW from '../../images/index/icons/blue-arrow.svg';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                             Single Partner Component                       */
/* -------------------------------------------------------------------------- */

function SinglePartner({ image, description, href, key }) {
  /* ------------------------------- CONST ------------------------------------ */
  // eslint-disable-next-line operator-linebreak
  const currentpath =
    typeof window !== 'undefined' ? window.location.pathname : '';

  // LOCALIZATION
  const { t } = useTranslation();
  const { siteWeb } = t('partners', { returnObjects: true });

  /* ******************************** RENDERING ******************************* */
  return (
    <Col
      className="single-partner-container fluid"
      data-aos={currentpath === '/' ? 'fade-up' : ''}
      data-aos-duration={currentpath === '/index' ? '1000' : ''}
      xxl={3}
      xl={3}
      lg={4}
      md={6}
      sm={12}
      xs={12}
      key={key}
    >
      <Container className="single-partner text-center fluid" fluid>
        <img src={image} alt="" />
        <p>{description}</p>
        <a
          className="website-link flex items-center justify-center"
          href={href}
          target="blank"
        >
          <h3>{siteWeb}</h3>
          <img src={NEXT_ARROW} alt="next-arrow" />
        </a>
      </Container>
    </Col>
  );
}

SinglePartner.propTypes = {
  key: PropTypes.number,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

SinglePartner.defaultProps = {
  key: undefined,
};

export default SinglePartner;

/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// PropTypes
import PropTypes from 'prop-types';

// UI lib component
import { Container, Col } from 'react-grid-system';

// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                             Single Solution Component                      */
/* -------------------------------------------------------------------------- */

function SingleReference({ image, alternative, name, onclick, elementKey }) {
  /* ------------------------------- CONST ------------------------------------ */
  // eslint-disable-next-line operator-linebreak
  const currentpath =
    typeof window !== 'undefined' ? window.location.pathname : '';

  /* ******************************** RENDERING ******************************* */
  return (
    <Col
      className="single-reference-container fluid"
      data-aos={currentpath === '/' ? 'fade-up' : ''}
      data-aos-duration={currentpath === '/index' ? '500' : ''}
      xxl={6}
      xl={6}
      lg={6}
      md={6}
      sm={12}
      xs={12}
      onClick={onclick}
      key={elementKey}
    >
      <Container className="single-reference fluid" fluid>
        <div className="overlay flex justify-center items-center">
          <h3>{name}</h3>
        </div>
        <img src={image} alt={alternative} />
      </Container>
    </Col>
  );
}

SingleReference.propTypes = {
  elementKey: PropTypes.number,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  alternative: PropTypes.string.isRequired,
  onclick: PropTypes.func.isRequired,
};

SingleReference.defaultProps = {
  elementKey: undefined,
};

export default SingleReference;

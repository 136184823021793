/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
// AOS ANIMATION Package
import AOS from 'aos';

// UI local components
import PageLayout from '../shared/PageLayout';
import Seo from '../shared/Seo';

import BannerSectionIndex from '../page-components/page-index/bannerSection';
import OurReferences from '../page-components/page-index/our-references';
import InfosSection from '../page-components/page-index/infos-section';
import OurPartners from '../page-components/page-index/our-partners';
import SloganSection from '../page-components/page-index/sloganSection';
import OurSolutions from '../page-components/page-index/our-solutions';
import ContactSection from '../page-components/page-index/contact';

import seoImage from '../images/banner.jpg';

// Styles
import '../page-styles/index.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function IndexPage() {
  /* ------------------------------- CONSTANSTS ----------------------------- */
  const SITE_TITLE = 'LUXOR TECHNOLOGY AND TRADE | Home';
  // eslint-disable-next-line operator-linebreak
  const SITE_DESCRIPTION =
    // eslint-disable-next-line max-len
    'Luxor technolgie & Trade est une société Tunisienne Spécialisée dans le développement des solutions techniques pour la construction. ';

  // eslint-disable-next-line operator-linebreak

  /* ********************************** HOOKS ********************************* */

  useEffect(() => {
    AOS.init({
      once: true,
      delay: '50ms',
    });
  });

  /* ******************************** RENDERING ******************************* */
  return (
    <PageLayout>
      <Seo title={SITE_TITLE} description={SITE_DESCRIPTION} image={seoImage} />
      <BannerSectionIndex />
      <OurSolutions />
      <OurReferences />
      <InfosSection />
      <OurPartners />
      <SloganSection />
      <ContactSection />
    </PageLayout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["HomePage", "Common", "Reference", "Partners", "Solution"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IndexPage;

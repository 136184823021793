/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';

// UI lib component
import { Container } from 'react-grid-system';
import { useTranslation } from 'react-i18next';

// Assets
import INNOVATION from '../../../images/index/infos-section/innovation.svg';
import DURABILITE from '../../../images/index/infos-section/durabilite.svg';
import QUALITE from '../../../images/index/infos-section/qualite.svg';
import CERTIF from '../../../images/index/infos-section/iso.svg';
// Style
import './index.scss';

/* -------------------------------------------------------------------------- */
/*                      Info SEction Of Index Page                            */
/* -------------------------------------------------------------------------- */

function InfosSection() {
  const { t } = useTranslation();
  const { name1, name2, name3, name4 } = t('infos', { returnObjects: true });

  /* ***************************** LOCAL VARIABLES **************************** */

  /* ******************************** CALLBACKS ******************************* */
  /**
   * Computes the perimeter
   */

  /* ------------------------------- CONST ------------------------------------ */

  /* ******************************** RENDERING ******************************* */
  return (
    <Container className="infos-section" fluid>
      <div className="all-infos flex items-center justify-around flex-flow">
        <Container
          className="single-info flex flex-column justify-center items-center tex-center fluid"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={INNOVATION} alt={name1} />
          <h2>{name1}</h2>
        </Container>
        <Container
          className="single-info flex flex-column justify-center items-center tex-center fluid"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={DURABILITE} alt={name2} />
          <h2>{name2}</h2>
        </Container>
        <Container
          className="single-info flex flex-column justify-center items-center tex-center fluid"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={QUALITE} alt={name3} />
          <h2>{name3}</h2>
        </Container>
        <Container
          className="single-info flex flex-column justify-center items-center tex-center fluid"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <img src={CERTIF} alt={name4} />
          <h2>{name4}</h2>
        </Container>
      </div>
    </Container>
  );
}

export default InfosSection;
